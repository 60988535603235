import combineStrings from "@/lib/combineStrings";

type Unit = {
  shorthand?: string;
  title: string;
  plural?: string;
};

type NutritionalInformation = {
  volumeToMassConversion: number;
  calories: number;
  fat: number;
  saturatedFat: number;
  carbohydrates: number;
  sugars: number;
  dietaryFibre: number;
  protein: number;
  sodium: number;
};

type Ingredient = {
  title: string;
  plural?: string;
  id: string;
  massPerUnit?: number;
  overrideNutritionalInformation?: boolean;
} & NutritionalInformation;

type RecipeIngredient = {
  typeHandle: string;
  heading?: string;
  unit?: Unit[];
  quantity?: number;
  ingredient?: Ingredient[];
  toServe?: boolean;
};

type NormalizedHeading = {
  type: "heading";
  heading: string;
  originalId?: number;
};

type NormalizedIngredient = {
  type: "ingredient";
  originalLabel?: string;
  originalId?: number;
  quantity: number;
  quantityRounded: number;
  unitSingular: string;
  unitPlural: string;
  ingredientId: number;
  ingredient: string;
  ingredientSingular: string;
  ingredientPlural: string;
  showIngredientNumber: boolean | number;
  sentence: string;
  unitFormatted: string;
  toServe: boolean;
  massPerUnit?: number;
  overrideNutritionalInformation?: boolean;
  overrideNutritionalInformationData?: NutritionalInformation;
};

export type NormalizedRecipeIngredient =
  | NormalizedHeading
  | NormalizedIngredient;

const normalizeRecipeIngredients = (
  recipeIngredients: RecipeIngredient[],
  servingSize = 4,
  convertMassPerUnit = false,
  measurement = "metric"
): NormalizedRecipeIngredient[] => {
  const normalizedIngredients = recipeIngredients.map((item) => {
    if (item.typeHandle !== "ingredient") {
      return {
        type: "heading",
        heading: item.heading,
        originalId: parseInt(item.id),
      } as NormalizedHeading;
    }

    let unitSingular = item.unit[0]
      ? item.unit[0].shorthand ?? item.unit[0].title
      : "";

    let unitPlural = item.unit[0]
      ? item.unit[0].shorthand ?? item.unit[0].plural ?? item.unit[0].title
      : "";

    const ingredient = item.ingredient[0] ?? null;

    let initialQuanity = item.quantity ?? 0;

    if (
      unitSingular === "" &&
      convertMassPerUnit &&
      ingredient.massPerUnit !== null
    ) {
      initialQuanity = initialQuanity * ingredient.massPerUnit;
      unitSingular = "g";
      unitPlural = "g";
    }

    let coreQuanity = initialQuanity / servingSize;
    let hasBeenConverted = false;

    if (unitSingular === "Knob") {
      switch (ingredient.title) {
        case "Butter":
        case "Ginger":
          coreQuanity = (initialQuanity * 25) / servingSize; // 25g per knob
          unitSingular = "g";
          unitPlural = "g";
      }
    }

    // Override the unit and quantity for certain ingredients if they don't give a quantity to ensure nutritional values are broadly accurate
    if (!coreQuanity && ingredient) {
      switch (ingredient.title) {
        case "Salt":
          coreQuanity = 2;
          unitSingular = "g";
          unitPlural = "g";
          hasBeenConverted = true;
          break;
        case "Pepper":
        case "Black Pepper":
          coreQuanity = 2;
          unitSingular = "g";
          unitPlural = "g";
          hasBeenConverted = true;
          break;
        case "Olive Oil":
        case "Vegetable Oil":
          coreQuanity = 5;
          unitSingular = "ml";
          unitPlural = "ml";
          hasBeenConverted = true;
          break;
        default:
          break;
      }
    }

    const showIngredientNumber = unitSingular || coreQuanity;

    const ingredientSingular = ingredient
      ? ingredient.title
      : "Unknown ingredient";
    const ingredientPlural = ingredient
      ? ingredient.plural
      : "Unknown ingredient";

    const uniqueKey = combineStrings([ingredientSingular, unitSingular]);

    // If the measurement is "imperial" then go through and change the units to imperial and convert the quantity
    if (measurement === "imperial") {
      switch (unitSingular) {
        case "g":
          unitSingular = "oz";
          coreQuanity = coreQuanity * 0.035274;
          break;
        case "ml":
          unitSingular = "fl oz";
          coreQuanity = coreQuanity * 0.035195;
          break;
        case "kg":
          unitSingular = "lb";
          coreQuanity = coreQuanity * 2.20462;
          break;
        case "L":
          unitSingular = "pt";
          coreQuanity = coreQuanity * 1.75975;
          break;
        default:
          break;
      }

      switch (unitPlural) {
        case "g":
          unitPlural = "oz";
          break;
        case "ml":
          unitPlural = "fl oz";
          break;
        case "kg":
          unitPlural = "lb";
          break;
        case "L":
          unitPlural = "pt";
          break;
        default:
          break;
      }
    }

    return {
      uniqueKey,
      type: "ingredient",
      originalLabel: item.label,
      originalId: parseInt(item.id), // need this to ensure the ingredients on a recipe are unique since two identical ingredients might appear
      quantity: coreQuanity,
      hasBeenConverted: hasBeenConverted,
      quantityRounded: Math.round(coreQuanity * 100) / 100,
      unitSingular,
      unitPlural,
      ingredientId: parseInt(ingredient?.id) || -1, // include the id of the ingredient for use in the shopping list: -1 if no ingredient
      ingredient: ingredientSingular,
      ingredientSingular: ingredientSingular,
      ingredientPlural: ingredientPlural,
      showIngredientNumber,
      sentence: showIngredientNumber
        ? `${coreQuanity} ${coreQuanity <= 1 ? unitSingular : unitPlural} ${
            coreQuanity <= 1 ? ingredientSingular : ingredientPlural
          }`
        : ingredientSingular,
      unitFormatted: `${coreQuanity} ${
        coreQuanity <= 1 ? unitSingular : unitPlural
      }`,
      toServe: item.toServe ?? false,
      massPerUnit: ingredient?.massPerUnit ?? null,
      overrideNutritionalInformation:
        ingredient?.overrideNutritionalInformation ?? false,
      overrideNutritionalInformationData:
        ingredient?.overrideNutritionalInformation
          ? {
              volumeToMassConversion: ingredient.volumeToMassConversion ?? 0,
              calories: ingredient.calories ?? 0,
              fat: ingredient.fat ?? 0,
              saturatedFat: ingredient.saturatedFat ?? 0,
              carbohydrates: ingredient.carbohydrates ?? 0,
              sugars: ingredient.sugars ?? 0,
              dietaryFibre: ingredient.dietaryFibre ?? 0,
              protein: ingredient.protein ?? 0,
              sodium: ingredient.sodium ?? 0,
            }
          : undefined,
    } as NormalizedIngredient;
  });

  return normalizedIngredients;
};

export default normalizeRecipeIngredients;
