const convertToAppropriateUnit = (quantity, unitSingular, unitPlural) => {
  let newQuantity = quantity;
  let newUnitSingular = unitSingular;
  let newUnitPlural = unitPlural;

  if (newUnitSingular === "g" && parseFloat(quantity) >= 1000) {
    newQuantity = quantity / 1000;
    newUnitSingular = "kg";
    newUnitPlural = "kg";
  }

  if (newUnitSingular === "kg" && parseFloat(quantity) < 1) {
    newQuantity = quantity * 1000;
    newUnitSingular = "g";
    newUnitPlural = "g";
  }

  if (newUnitSingular === "ml" && parseFloat(quantity) >= 1000) {
    newQuantity = quantity / 1000;
    newUnitSingular = "L";
    newUnitPlural = "L";
  }

  if (newUnitSingular === "L" && parseFloat(quantity) < 1) {
    newQuantity = quantity * 1000;
    newUnitSingular = "ml";
    newUnitPlural = "ml";
  }

  let rounded = Math.round(newQuantity * 100) / 100;

  if (rounded % 1 !== 0) {
    if (newUnitSingular === "kg" || newUnitSingular === "L") {
      // Round to the nearest 0.5
      rounded = Math.round(rounded * 2) / 2;
    } else {
      // Round to the nearest 0.25
      rounded = Math.round(rounded * 4) / 4;
    }
  }

  return {
    quantity: newQuantity,
    unitSingular: newUnitSingular,
    unitPlural: newUnitPlural,
    quantityRounded: rounded,
  };
};

export default convertToAppropriateUnit;
