import { useContext, useMemo } from "react";
import { PlannerContext } from "@/contexts/PlannerContext";
import { SHOPPING_LIST_GUID } from "./ShoppingListController";
import Image from "next/image";
import clsx from "clsx";

const ShoppingListButton = () => {
  const { setIsShoppingListOpen, planners } = useContext(PlannerContext);

  const shoppingList = useMemo(
    () => planners.find((planner) => planner.title === SHOPPING_LIST_GUID),
    [planners]
  );
  const recipes = useMemo(
    () => shoppingList?.days?.[0]?.dayRecipes,
    [shoppingList]
  );

  const handleClick = () => {
    setIsShoppingListOpen(true);
  };

  return (
    <div
      onClick={handleClick}
      className="w-full cursor-pointer rounded-lg border border-[#e4e3e8] bg-white px-4 py-3 font-sans shadow-[0px_4px_12px_0px_rgba(0,0,0,0.20)]"
    >
      <div className="flex justify-between">
        <div className="flex flex-col items-start justify-center">
          <div className="text-sm font-medium text-black md:text-base">
            Shopping List
          </div>
          <div className="font-body text-[13px] font-medium text-zinc-500 md:text-sm">
            {recipes && recipes.length > 0
              ? "View List"
              : "Add recipes to your shopping list"}
          </div>
        </div>
        {recipes && recipes.length > 0 ? (
          <div className="flex items-center gap-2">
            <div className="flex">
              {recipes.slice(0, 3).map(({ recipe }, i) => (
                <Image
                  key={i}
                  width={40}
                  height={40}
                  src={recipe.cachedData.image?.[0]?.url}
                  alt={recipe.cachedData.image?.[0]?.title}
                  className={clsx(
                    "box-border rounded-lg border-2 border-white",
                    {
                      "-ml-2": i > 0,
                    }
                  )}
                  style={{ zIndex: recipes.length - i }}
                />
              ))}
            </div>
            <div className="font-body text-sm font-medium text-zinc-500">
              {recipes.length}
            </div>
          </div>
        ) : (
          <div data-svg-wrapper>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="20" fill="#F4F4F5" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.5075 13.3075C17.9175 12.8975 18.5824 12.8975 18.9924 13.3075L24.9424 19.2575C25.3525 19.6676 25.3525 20.3324 24.9424 20.7425L18.9924 26.6925C18.5824 27.1025 17.9175 27.1025 17.5075 26.6925C17.0974 26.2824 17.0974 25.6176 17.5075 25.2075L22.715 20L17.5075 14.7925C17.0974 14.3824 17.0974 13.7176 17.5075 13.3075Z"
                fill="black"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShoppingListButton;
