import { useMediaQuery } from "@/src/hooks/useMediaQuery";
import { ToastContainer, toast, UpdateOptions } from "react-toastify";
import { useEffect, useRef, useContext, useMemo } from "react";
import { PlannerContext } from "@/contexts/PlannerContext";
import ShoppingListButton from "./ShoppingListButton";
import ShoppingList from "./";
import fetchGetPlannersByUserId from "@/lib/planner/fetchGetPlannersByUserId";
import { useStores } from "@/hooks/useStores";
import fetchAddRecipeToPlanner from "@/lib/planner/fetchAddRecipeToPlanner";
import fetchCreatePlanner from "@/lib/planner/fetchCreatePlanner";
import fetchUpdatePlannerDays from "@/lib/planner/fetchUpdatePlannerDays";

export const SHOPPING_LIST_GUID = "03f098dd-5a1a-4244-9106-1a7fdd7d0179";

export default function ShoppingListController() {
  const toastId = useRef(null);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { authStore } = useStores();
  const { currentUser } = authStore;
  const {
    planners,
    isShoppingListOpen,
    setPlanners,
    setLastFetchedAt,
    lastFetchedAt,
  } = useContext(PlannerContext);

  const toastProps = {
    autoClose: false,
    className:
      "p-6 m-0 box-content shadow-none border-none bg-transparent w-[342px]",
    closeButton: false,
    closeOnClick: false,
    position: isMobile ? "bottom-center" : "bottom-right",
    toastId: "shopping-list-toast",
    draggable: false,
  } as UpdateOptions<unknown>;

  const shoppingList = useMemo(() => {
    return planners.find((planner) => planner.title === SHOPPING_LIST_GUID);
  }, [planners]);

  const getInitialPlanners = async () => {
    try {
      const fetchedPlanners = await fetchGetPlannersByUserId(currentUser.id, {
        hidden: true,
      });
      setPlanners(fetchedPlanners);
      setLastFetchedAt(new Date());
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (toastId.current) {
      toast.update(toastId.current, toastProps);
    } else {
      toastId.current = toast(<ShoppingListButton />, toastProps);
    }
  }, [isMobile]);

  useEffect(() => {
    if (isShoppingListOpen) {
      toast.dismiss(toastId.current);
    } else {
      toast(<ShoppingListButton />, toastProps);
    }
  }, [isShoppingListOpen]);

  useEffect(() => {
    if (!currentUser) return;

    // If the planners were last fetched 10 minutes ago, don't fetch them again
    if (
      lastFetchedAt &&
      new Date().getTime() - lastFetchedAt.getTime() < 10 * 60 * 1000
    ) {
      return;
    }

    getInitialPlanners();
  }, [planners, setPlanners, currentUser, lastFetchedAt, setLastFetchedAt]);

  return (
    <>
      <ToastContainer />
      <ShoppingList shoppingList={shoppingList} />
    </>
  );
}

export const addRecipeToShoppingList = async ({
  recipe,
  shoppingList,
  currentUser,
}) => {
  if (shoppingList) {
    try {
      const response = await fetchAddRecipeToPlanner(
        recipe.id,
        shoppingList.uid,
        currentUser.id
      );

      return response;
    } catch (error) {
      console.error("Error:", error);
    }
  } else {
    try {
      const response = await fetchCreatePlanner(
        SHOPPING_LIST_GUID,
        {
          id: currentUser.id,
          uid: currentUser.uid,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          email: currentUser.email,
        },
        false,
        {
          recipeIds: [recipe.id],
          hidden: true,
        }
      );

      return response;
    } catch (error) {
      console.error("Error:", error);
    }
  }
};

export const removeRecipeFromShoppingList = async ({
  shoppingList,
  recipe,
}) => {
  const newDays = shoppingList.days.map((plannerDay) => {
    return {
      ...plannerDay,
      dayRecipes: plannerDay.dayRecipes.filter(
        (dayRecipe) => dayRecipe.recipeId.toString() !== recipe.id.toString()
      ),
    };
  });

  const updatedDays = await fetchUpdatePlannerDays(shoppingList.uid, newDays);

  return { ...shoppingList, days: updatedDays };
};
