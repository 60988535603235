import {
  PlannerWithDaysAndRecipes,
  UserWithoutTimestamps,
} from "@/types/planner";

interface FetchCreatePlannerOptions {
  mealPlanId?: number;
  recipeIds?: number[];
}

async function fetchCreatePlanner(
  title: string,
  user: UserWithoutTimestamps,
  dummy: boolean,
  options: FetchCreatePlannerOptions = {}
): Promise<PlannerWithDaysAndRecipes> {
  const { mealPlanId = undefined, recipeIds = [], hidden = false } = options;

  try {
    const response = await fetch("/api/planner/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        user,
        dummy,
        mealPlanId,
        recipeIds,
        hidden,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Planner created successfully:", data.planner);
      return data.planner;
    } else {
      const errorData = await response.json();
      console.error("Error creating meal plan:", errorData.error);
      throw new Error(errorData.error);
    }
  } catch (error) {
    console.error("Error creating meal plan:", error.message);

    throw error;
  }
}

export default fetchCreatePlanner;
