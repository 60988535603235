// @ts-nocheck

import combineStrings from "@/lib/combineStrings";
import normalizeRecipeIngredients, {
  NormalizedRecipeIngredient,
} from "@/lib/ingredients/normalizeRecipeIngredients";
import pantryIngredients from "@/lib/ingredients/pantryIngredients";
import { PlannerDayRecipeWithRecipe, RecipeIngredient } from "@/types/planner";
import trim from "lodash/trim";

/* This is complex logic so I've tried to make it as functional as possible. It's a series of steps that take the data and transform it into a more useful format. - AF */
const combineIngredients = (
  planRecipes: PlannerDayRecipeWithRecipe[],
  measurement = "metric"
) => {
  // Initialise the data
  const { foods, rawLines } = initFoods(planRecipes);

  // Loop through foods
  const combinedUnitFoods = combineUnitsOfFood(foods, rawLines);

  // Clean problematic food types
  const cleanedGarlicUp = mergeGarlicCloveIntoGarlic(combinedUnitFoods);

  // Cleanse the units of foods
  const cleansedUnitsFood = cleanseUnits(cleanedGarlicUp);

  // Loop through foods and prepare the output as a NormalizedRecipeIngredient
  const normalizedRecipeIngredients = renormalizeRecipeIngredients(
    cleansedUnitsFood,
    measurement
  );

  // Return alphabetized ingredients so dupes are grouped together
  return alhpabetizeIngredients(normalizedRecipeIngredients);
};

/* ------------------ */
/* Steps follow below */
/* ------------------ */

/*
  Initialise the foods and rawLines array by traversing planRecipes
*/
const initFoods = (planRecipes: PlannerDayRecipeWithRecipe[]) => {
  const foods = {};
  const rawLines = [];
  planRecipes.forEach((planRecipe) => {
    const scaledServingSize =
      planRecipe.baseServingSize / planRecipe.servingSize;

    // Get the recipe ingredients
    const recipeIngredients = planRecipe.recipe?.cachedData?.recipeIngredients;

    // If there are no recipe ingredients for some reason, return
    if (!recipeIngredients || recipeIngredients === undefined) return;

    // Filter out recipe ingredients that have type heading
    const filteredRecipeIngredients = recipeIngredients.filter(
      (ingredient) => ingredient.typeHandle === "ingredient"
    );

    // Normalize the recipe ingredients
    const normalizedRecipeIngredients = normalizeRecipeIngredients(
      filteredRecipeIngredients,
      scaledServingSize
    );

    // Loop through the normalized recipe ingredients
    normalizedRecipeIngredients.forEach((ingredient) => {
      if (ingredient.type !== "ingredient") return;

      // Skip if the ingredient's ID is in the pantry list
      if (pantryIngredients.includes(ingredient.ingredientId)) return;

      const key = trim(ingredient.ingredientSingular);

      // If we haven't come across this food before, add it to the foods object
      if (!foods[key]) {
        foods[key] = {
          key: key,
          ingredient: ingredient.ingredient,
          ingredientId: ingredient.ingredientId,
          ingredientPlural: ingredient.ingredientPlural,
          ingredientSingular: ingredient.ingredientSingular,
          lines: {},
        };
      }

      // Add raw food line to rawLines object
      rawLines.push({
        ingredientKey: key,
        quantity: ingredient.quantity,
        unitFormatted: ingredient.unitFormatted,
        unitPlural: ingredient.unitPlural,
        unitSingular: ingredient.unitSingular,
        recipe: planRecipe.recipe,
      });
    });
  });

  return { foods, rawLines };
};

/**
 * Garlic is particularly messy in the site IA. This will move "Garlic Clove" into "Garlic" and combine the quantities
 */
const mergeGarlicCloveIntoGarlic = (foods) => {
  // Get garlic cloves from foods
  Object.values(foods)
    .filter((food) => food.ingredient === "Garlic Clove")
    .forEach((garlicClove) => {
      // Create a garlic food if it doesn't exist
      if (!foods["Garlic"]) {
        foods["Garlic"] = {
          key: "Garlic",
          ingredient: "Garlic",
          ingredientId: "garlic", // TODO: This might cause problems being harcoded like this?
          ingredientPlural: "Garlics",
          ingredientSingular: "Garlic",
          lines: {},
        };
      }

      // Loop through each line and add it to the "Garlic" food
      for (const [unit, line] of Object.entries(garlicClove.lines)) {
        foods["Garlic"].lines["Clove"] = {
          ingredientKey: "Garlic",
          quantity: line.quantity,
          unitPlural: "Cloves",
          unitSingular: "Clove",
          recipes: line.recipes,
        };
      }

      // Then delete any old garlicCloves
      delete foods[garlicClove.key];
    });

  return foods;
};

// This loops through each food and finds each distinct unit and combines them into one object so it's easier to traverse and compare the units in use
const combineUnitsOfFood = (foods, rawLines) => {
  for (const food of Object.values(foods)) {
    const linesThatUseThisFood = rawLines.filter(
      (line) => line.ingredientKey === food.key
    );

    const byUnit = {};

    // Loop through each linesThatUseThisFood, and group them by unit
    linesThatUseThisFood.forEach((line) => {
      if (!byUnit[line.unitSingular]) {
        byUnit[line.unitSingular] = {
          ingredientKey: line.ingredientKey,
          quantity: parseFloat(line.quantity),
          unitPlural: line.unitPlural,
          unitSingular: line.unitSingular,
          recipes: [line.recipe.cachedData],
        };
      } else {
        byUnit[line.unitSingular].quantity += parseFloat(line.quantity);
        byUnit[line.unitSingular].recipes.push(line.recipe.cachedData);
      }
    });
    food.lines = byUnit;
  }

  return foods;
};

// Loops through foods (now with combined units) and tries to clean the units as much as possible
const cleanseUnits = (foods) => {
  for (const food of Object.values(foods)) {
    // Does this food have ml or l?
    const hasVolume = food.lines["ml"] || food.lines["L"];

    // Does this food have tsp or tbsp?
    const hasSpoons = food.lines["tsp"] || food.lines["tbsp"];

    // If the food is "Garlic" and the unit is "Clove"...
    const clovesInAGarlic = 10;

    if (food.key === "Garlic" && food.lines["Clove"]) {
      if (!food.lines["Bulb"]) {
        food.lines["Bulb"] = {
          ingredientKey: food.key,
          quantity: 0,
          unitPlural: "Bulbs",
          unitSingular: "Bulb",
          recipes: [],
        };
      }

      // ... then turn it into whole units of garlic wher 10 cloves = 1 garlic, adding to the existing garlic
      food.lines["Bulb"].quantity = Math.ceil(
        food.lines["Bulb"].quantity +
          parseFloat(food.lines["Clove"].quantity) / clovesInAGarlic
      );

      food.lines["Bulb"].recipes = [
        food.lines["Bulb"].recipes,
        ...food.lines["Clove"].recipes,
      ];

      // Remoe the cloves
      delete food.lines["Clove"];
    }

    if (food.key === "Garlic" && food.lines[""]) {
      if (!food.lines["Bulb"]) {
        food.lines["Bulb"] = {
          ingredientKey: food.key,
          quantity: 0,
          unitPlural: "Bulbs",
          unitSingular: "Bulb",
          recipes: [],
        };
      }

      food.lines["Bulb"] = {
        ingredientKey: food.key,
        quantity: Math.ceil(
          food.lines["Bulb"].quantity + food.lines[""].quantity
        ),
        unitPlural: "",
        unitSingular: "",
        recipes: [food.lines["Bulb"], ...food.lines[""].recipes],
      };

      delete food.lines[""];
    }

    // Merge g and kg
    if (food.lines["kg"]) {
      // If food.lines["tsp"] doesn't exist, create it
      if (!food.lines["g"]) {
        food.lines["g"] = {
          ingredientKey: food.key,
          quantity: 0,
          unitPlural: "g",
          unitSingular: "g",
          recipes: [],
        };
      }
      food.lines["g"].quantity += parseFloat(food.lines["kg"].quantity) * 1000;

      // Add the recipes
      food.lines["g"].recipes = [
        food.lines["g"].recipes,
        ...food.lines["kg"].recipes,
      ];

      delete food.lines["kg"];
    }

    // Merge ml and l
    if (food.lines["L"]) {
      // If food.lines["tsp"] doesn't exist, create it
      if (!food.lines["ml"]) {
        food.lines["ml"] = {
          ingredientKey: food.key,
          quantity: 0,
          unitPlural: "ml",
          unitSingular: "ml",
          recipes: [],
        };
      }
      food.lines["ml"].quantity += parseFloat(food.lines["L"].quantity) * 1000;

      // Add the recipes
      food.lines["ml"].recipes = [
        food.lines["ml"].recipes,
        ...food.lines["L"].recipes,
      ];

      delete food.lines["L"];
    }

    // Merge tbsp and tsp
    if (food.lines["tbsp"]) {
      // If food.lines["tsp"] doesn't exist, create it
      if (!food.lines["tsp"]) {
        food.lines["tsp"] = {
          ingredientKey: food.key,
          quantity: 0,
          unitPlural: "tsp",
          unitSingular: "tsp",
          recipes: [],
        };
      }

      food.lines["tsp"].quantity += food.lines["tbsp"].quantity * 3; // 1 tablespoon = 3 teaspoons

      // Add the recipes
      food.lines["tsp"].recipes = [
        food.lines["tsp"].recipes,
        ...food.lines["tbsp"].recipes,
      ];

      delete food.lines["tbsp"];
    }

    // When considering handfuls and bunches we have different amounts for fresh herbs
    const freshHerbs = [
      "Basil",
      "Parsley",
      "Rosemary",
      "Thyme",
      "Oregano",
      "Dill",
      "Cilantro",
      "Mint",
      "Sage",
      "Chives",
      "Tarragon",
      "Lemongrass",
    ];
    // If the food is in freshHerbs
    if (freshHerbs.includes(food.key)) {
      convertArbitraryUnitsToGrams(food, "Handful", 15);
    } else {
      convertArbitraryUnitsToGrams(food, "Handful", 30);
    }
    convertArbitraryUnitsToGrams(food, "Large Handful", 30);
    convertArbitraryUnitsToGrams(food, "Small packet", 30);
    convertArbitraryUnitsToGrams(food, "Large packet", 100);
    convertArbitraryUnitsToGrams(food, "Small bunch", 25);
    convertArbitraryUnitsToGrams(food, "Bunch", 50);
    convertArbitraryUnitsToGrams(food, "Large bunch", 100);

    // If we don't have any spoons we have no further work to do
    if (!hasSpoons) continue;

    if (hasVolume) {
      const tspToMl = 5;
      // If we have tsp and ml, convert tsp to ml
      if (food.lines["tsp"]) {
        if (!food.lines["ml"]) {
          food.lines["ml"] = {
            ingredientKey: food.key,
            quantity: 0,
            unitPlural: "ml",
            unitSingular: "ml",
            recipes: [],
          };
        }
        food.lines["ml"].quantity +=
          parseFloat(food.lines["tsp"].quantity) * tspToMl;

        // Add the recipes
        food.lines["ml"].recipes = [
          food.lines["ml"].recipes,
          ...food.lines["tsp"].recipes,
        ];
        delete food.lines["tsp"];
      }

      continue;
    } else {
      const tspToGrams = 10; // This is a rough estimate, way more than needed on most recipes but there's not a clean way to calculate this. In future we'd like to use AI

      // If we have tsp and g, convert tsp to g
      if (food.lines["tsp"]) {
        if (!food.lines["g"]) {
          food.lines["g"] = {
            ingredientKey: food.key,
            quantity: 0,
            unitPlural: "g",
            unitSingular: "g",
            recipes: [],
          };
        }
        food.lines["g"].quantity +=
          parseFloat(food.lines["tsp"].quantity) * tspToGrams;
        // Add the recipes
        food.lines["g"].recipes = [
          food.lines["g"].recipes,
          ...food.lines["tsp"].recipes,
        ];
        delete food.lines["tsp"];
      }
    }
  }
  return foods;
};

const convertArbitraryUnitsToGrams = (food, unit, grams) => {
  // Convert "Handful"
  if (food.lines[unit]) {
    // If food.lines["tsp"] doesn't exist, create it
    if (!food.lines["g"]) {
      food.lines["g"] = {
        ingredientKey: food.key,
        quantity: 0,
        unitPlural: "g",
        unitSingular: "g",
        recipes: [],
      };
    }

    food.lines["g"].quantity += parseFloat(food.lines[unit].quantity) * grams;

    // Add the recipes
    food.lines["g"].recipes = [
      food.lines["g"].recipes,
      ...food.lines[unit].recipes,
    ];

    delete food.lines[unit];
  }
};

// This takes our internal foods and lines and converts them into a normalized recipe ingredient so the frontend can consume them
const renormalizeRecipeIngredients = (foods, measurement = "metric") => {
  const normalizedRecipeIngredients = [];

  for (const food of Object.values(foods)) {
    for (const line of Object.values(food.lines)) {
      // If the measurement is "imperial" then go through and change the units to imperial and convert the quantity
      if (measurement === "imperial") {
        switch (line.unitSingular) {
          case "g":
            line.unitSingular = "oz";
            line.quantity = line.quantity * 0.035274;
            break;
          case "ml":
            line.unitSingular = "fl oz";
            line.quantity = line.quantity * 0.035195;
            break;
          case "kg":
            line.unitSingular = "lb";
            line.quantity = line.quantity * 2.20462;
            break;
          case "L":
            line.unitSingular = "pt";
            line.quantity = line.quantity * 1.75975;
            break;
          default:
            break;
        }

        switch (line.unitPlural) {
          case "g":
            line.unitPlural = "oz";
            break;
          case "ml":
            line.unitPlural = "fl oz";
            break;
          case "kg":
            line.unitPlural = "lb";
            break;
          case "L":
            line.unitPlural = "pt";
            break;
          default:
            break;
        }
      }

      const uniqueKey = combineStrings([
        food.ingredientSingular,
        line.unitSingular,
      ]);

      normalizedRecipeIngredients.push({
        type: "ingredient",
        quantity: line.quantity,
        quantityRounded: Math.ceil(line.quantity), // Always round up, we don't want to show 0.5 of something
        unitSingular: line.unitSingular,
        unitPlural: line.unitPlural,
        ingredientId: food.key,
        ingredient: food.ingredient,
        ingredientSingular: food.ingredientSingular,
        ingredientPlural: food.ingredientPlural,
        showIngredientNumber: true, // TODO?
        sentence: "TODO",
        unitFormatted: "TODO",
        toServe: false, // TODO
        uniqueKey,
        recipes: line.recipes,
      });
    }
  }

  return normalizedRecipeIngredients;
};

/** Alphabetise the lists */
const alhpabetizeIngredients = (ingredients) => {
  return ingredients.sort((a, b) => {
    if (a.ingredientSingular < b.ingredientSingular) {
      return -1;
    }
    if (a.ingredientSingular > b.ingredientSingular) {
      return 1;
    }
    return 0;
  });
};

export default combineIngredients;
