import convertToAppropriateUnit from "@/lib/ingredients/convertToAppropriateUnit";

const ScaledIngredientLabel = ({ ingredient, scale = 1 }) => {
  const decimalSize = ingredient.quantityRounded * scale;

  const appropriateUnit = convertToAppropriateUnit(
    decimalSize,
    ingredient.unitSingular,
    ingredient.unitPlural
  );

  return (
    <span className="capitalize">
      {parseFloat(appropriateUnit.quantityRounded) <= 1
        ? ingredient.ingredientSingular
        : ingredient.ingredientPlural}
    </span>
  );
};

export default ScaledIngredientLabel;
