import convertToAppropriateUnit from "@/lib/ingredients/convertToAppropriateUnit";
import tidyQuantityIntoFractions from "@/lib/ingredients/tidyQuantityIntoFractions";

const ScaledIngredientQuantity = ({ ingredient, scale = 1 }) => {
  const decimalSize = ingredient.quantityRounded * scale;

  const appropriateUnit = convertToAppropriateUnit(
    decimalSize,
    ingredient.unitSingular,
    ingredient.unitPlural
  );

  const fractions = tidyQuantityIntoFractions(
    appropriateUnit.quantityRounded,
    ingredient.unit
  );

  return (
    <>
      {ingredient.showIngredientNumber ? (
        <>
          {fractions.quantityRounded}{" "}
          {decimalSize <= 1
            ? appropriateUnit.unitSingular
            : appropriateUnit.unitPlural}
        </>
      ) : ingredient.toServe ? (
        <>To serve</>
      ) : (
        <></>
      )}
    </>
  );
};

export default ScaledIngredientQuantity;
