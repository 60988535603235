// PlannerContext.ts
import fetchCopyPlanner from "@/lib/planner/fetchCopyPlanner";
import fetchDeletePlanner from "@/lib/planner/fetchDeletePlanner";
import fetchUpdatePlannerDays from "@/lib/planner/fetchUpdatePlannerDays";
import { Planner, PlannerDay, PlannerDayRecipe } from "@prisma/client";
import React, { createContext, useContext, useEffect, useState } from "react";

interface PlannerWithDaysAndRecipes extends Planner {
  days: (PlannerDay & { dayRecipes: PlannerDayRecipe[] })[];
}

interface PlannerContextValue {
  planners: Array<PlannerWithDaysAndRecipes>;
  sortedPlanners: Array<PlannerWithDaysAndRecipes>;
  findPlannerByUid: (uid: string) => PlannerWithDaysAndRecipes | undefined;
  lastFetchedAt: Date | null;
  setPlanners: (planners: Array<PlannerWithDaysAndRecipes>) => void;
  setLastFetchedAt: (lastFetchedAt: Date) => void;
  isRecipeInPlanners: (recipeId: number) => boolean;
  updatePlanner: (
    uid: string,
    newPlanner: PlannerWithDaysAndRecipes,
    markAsSaved?: boolean
  ) => void;
  addPlanner: (planner: PlannerWithDaysAndRecipes) => void;
  isShoppingListOpen: boolean;
  setIsShoppingListOpen: (isShoppingListOpen: boolean) => void;
  markPlannerAsUnsaved: (uid: string) => void;
  markPlannerAsSaved: (uid: string) => void;
  isPlannerUnsaved: (uid: string) => boolean;
  isPlannerSaving: (uid: string) => boolean;
  savePlanner: (
    uid: string,
    overridePlanner: PlannerWithDaysAndRecipes
  ) => Promise<void>;
  isPlannerDeleting: (uid: string) => boolean;
  deletePlanner: (uid: string) => Promise<void>;
  autosaveEnabled: boolean;
  setAutosaveEnabled: (autosaveEnabled: boolean) => void;
  plannerCount: number;
  sortingOptions: { value: string; label: string }[];
  selectedSortingOption: string;
  setSelectedSortingOption: (value: string) => void;
  sortDirection: "asc" | "desc";
  setSortDirection: (direction: "asc" | "desc") => void;
  copyPlanner: (
    uid: string,
    newUserId: string
  ) => Promise<PlannerWithDaysAndRecipes>;
}
const PlannerContext = createContext<PlannerContextValue | null>(null);

const usePlannerContext = () => {
  const context = useContext(PlannerContext);
  if (!context) {
    throw new Error(
      "usePlannerContext must be used within a PlannerContextProvider"
    );
  }
  return context;
};

const sortingOptions = [
  { value: "date", label: "Date" },
  { value: "alpha", label: "Alphabetical" },
];

const PlannerContextProvider: React.FC = ({ children }) => {
  const [planners, setPlanners] = useState<Array<PlannerWithDaysAndRecipes>>(
    []
  );
  const [sortedPlanners, setSortedPlanners] = useState<
    Array<PlannerWithDaysAndRecipes>
  >([]);
  const [autosaveEnabled, setAutosaveEnabled] = useState<boolean>(false);
  const [unsavedPlanners, setUnsavedPlanners] = useState<string[]>([]);
  const [savingPlanners, setSavingPlanners] = useState<string[]>([]);
  const [lastFetchedAt, setLastFetchedAt] = useState<Date | null>(null);

  const [plannerCount, setPlannerCount] = useState<number>(0);

  const [selectedSortingOption, setSelectedSortingOption] = useState(
    sortingOptions.length > 0 ? sortingOptions[0].value : null
  );
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  // Update plannerCount whenever planners change
  useEffect(() => {
    setPlannerCount(planners.length);
  }, [planners]);

  // Sync plannerCount with localStorage whenever it changes
  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem("plannerCount", JSON.stringify(plannerCount));
    }
  }, [plannerCount]);

  // Read from localStorage when component mounts
  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedPlannerCount = localStorage.getItem("plannerCount");
      if (storedPlannerCount) {
        setPlannerCount(JSON.parse(storedPlannerCount));
      }
    }
  }, []);

  // Sorting function
  const sortPlanners = (planners: Array<PlannerWithDaysAndRecipes>) => {
    return [...planners].sort((a, b) => {
      if (selectedSortingOption === "alpha") {
        // Sorting by title (alphabetically)
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
        if (titleA < titleB) return sortDirection === "asc" ? -1 : 1;
        if (titleA > titleB) return sortDirection === "asc" ? 1 : -1;
        return 0;
      } else {
        // Sorting by date (using createdAt)
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
      }
    });
  };

  // Watch for changes in sorting options or planners and re-sort planners
  useEffect(() => {
    const sorted = sortPlanners(planners);
    setSortedPlanners(sorted);
  }, [selectedSortingOption, sortDirection, planners]);

  const findPlannerByUid = (uid: string) => {
    return planners.find((planner) => planner.uid === uid);
  };

  const isRecipeInPlanners = (recipeId: number): boolean => {
    return planners.some((planner) =>
      planner.days.some((day) =>
        day.dayRecipes.some((dayRecipe) => dayRecipe.recipeId === recipeId)
      )
    );
  };

  const markPlannerAsUnsaved = (uid: string) => {
    setUnsavedPlanners((prevUnsavedPlanners) =>
      prevUnsavedPlanners.includes(uid)
        ? prevUnsavedPlanners
        : [...prevUnsavedPlanners, uid]
    );
  };

  const markPlannerAsSaved = (uid: string) => {
    setUnsavedPlanners((prevUnsavedPlanners) =>
      prevUnsavedPlanners.filter((plannerId) => plannerId !== uid)
    );
  };

  const isPlannerUnsaved = (uid: string) => {
    return unsavedPlanners.includes(uid);
  };

  const isPlannerSaving = (uid: string): boolean => {
    return savingPlanners.includes(uid);
  };

  const [deletingPlanners, setDeletingPlanners] = useState<string[]>([]);

  const isPlannerDeleting = (uid: string): boolean => {
    return deletingPlanners.includes(uid);
  };

  const deletePlanner = async (uid: string) => {
    const planner = findPlannerByUid(uid);

    if (!planner) {
      console.error("Could not find planner with given uid");
      return;
    }

    setDeletingPlanners((prev) => [...prev, uid]); // Mark planner as being deleted

    try {
      // Call API to delete planner
      await fetchDeletePlanner(uid);

      // If the above line doesn't throw an error, the deletion was successful
      setPlanners((prev) => prev.filter((planner) => planner.uid !== uid)); // remove deleted planner from planners

      // Mark planner as done deleting
      setDeletingPlanners((prev) =>
        prev.filter((plannerId) => plannerId !== uid)
      );
    } catch (error) {
      // Handle failure case here
      setDeletingPlanners((prev) =>
        prev.filter((plannerId) => plannerId !== uid)
      );
      console.error("Error deleting planner:", error);
    }
  };

  const updatePlanner = (
    uid: string,
    newPlanner: PlannerWithDaysAndRecipes,
    markAsSaved = false
  ): void => {
    setPlanners((prevPlanners) =>
      prevPlanners.map((planner) =>
        planner.uid === uid ? newPlanner : planner
      )
    );
    if (!markAsSaved) markPlannerAsUnsaved(uid);
    else markPlannerAsSaved(uid);
  };

  const addPlanner = (planner: PlannerWithDaysAndRecipes) => {
    setPlanners((prevPlanners) => [...prevPlanners, planner]);
  };

  const [isShoppingListOpen, setIsShoppingListOpen] = useState(false);

  const savePlanner = async (
    uid: string,
    overridePlanner: PlannerWithDaysAndRecipes
  ) => {
    const planner = overridePlanner ?? findPlannerByUid(uid);

    if (!planner) {
      console.error("Could not find planner with given uid");
      return;
    }

    setSavingPlanners((prev) => [...prev, uid]); // Mark planner as being saved

    const updatedDays = await fetchUpdatePlannerDays(planner.uid, planner.days);

    if (updatedDays) {
      const updatedPlanner = { ...planner, days: updatedDays };
      updatePlanner(planner.uid, updatedPlanner, true);
    } else {
      console.error("Error updating planner");
    }

    setSavingPlanners((prev) => prev.filter((uid) => uid !== planner.uid)); // Mark planner as done saving
  };

  const copyPlanner = async (uid: string, newUserId: string) => {
    const planner = findPlannerByUid(uid);

    if (!planner) {
      console.error("Could not find planner with given uid");
      return;
    }

    try {
      // Call API to copy planner
      const response = await fetchCopyPlanner(uid, newUserId);

      console.log("copiedPlanner response", response);

      // If the above line doesn't throw an error, the copy was successful
      if (response.success && response.copiedPlanner) {
        setPlanners((prev) => [...prev, response.copiedPlanner]); // add copied planner to planners
        return response.copiedPlanner;
      } else {
        console.error("Error copying planner");
      }
    } catch (error) {
      // Handle failure case here
      console.error("Error copying planner:", error);
    }
  };

  const value: PlannerContextValue = {
    planners,
    sortedPlanners,
    findPlannerByUid,
    lastFetchedAt,
    setPlanners,
    setLastFetchedAt,
    isRecipeInPlanners,
    updatePlanner,
    addPlanner,
    isShoppingListOpen,
    setIsShoppingListOpen,
    markPlannerAsSaved,
    markPlannerAsUnsaved,
    isPlannerUnsaved,
    isPlannerSaving,
    savePlanner,
    deletePlanner,
    isPlannerDeleting,
    autosaveEnabled,
    setAutosaveEnabled,
    plannerCount,
    sortingOptions,
    selectedSortingOption,
    setSelectedSortingOption,
    sortDirection,
    setSortDirection,
    copyPlanner,
  };

  return (
    <PlannerContext.Provider value={value}>{children}</PlannerContext.Provider>
  );
};

export { PlannerContext, PlannerContextProvider, usePlannerContext };
