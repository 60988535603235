import { CheckIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

const IngredientCheckButton = ({ isChecked, onClick }) => {
  return (
    <button
      className={clsx(
        "ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-2 transition ease-out lg:h-7 lg:w-7",
        {
          "bg-zinc-100 text-zinc-950 focus:border-zinc-300": !isChecked,
          "border-zinc-950 bg-zinc-950 text-white focus:border-zinc-950":
            isChecked,
        }
      )}
      onClick={onClick}
    >
      <div
        className={clsx("text-accent-contrast transition ease-out", {
          "opacity-100 group-hover:opacity-100": isChecked,
          "opacity-0 group-hover:opacity-50": !isChecked,
        })}
      >
        <CheckIcon className={clsx("h-3 w-3 stroke-2 lg:h-4 lg:w-4")} />
      </div>
    </button>
  );
};

export default IngredientCheckButton;
