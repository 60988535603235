const tidyQuantityIntoFractions = (quantity, unit) => {
  let qty = 0;
  // If the quantity is a whole number or a metric unit, don't convert to a fraction
  if (
    quantity % 1 === 0 ||
    unit === "g" ||
    unit === "ml" ||
    unit === "kg" ||
    unit === "L"
  ) {
    qty = quantity;
  } else {
    // Replace 0.5 with ½, 0.33 with ⅓, 0.66 with ⅔, 0.25 with ¼, 0.75 with ¾, etc. as long as it's the last character in the string
    qty = quantity
      .toString()
      .replace(/\.5$/, "½")
      .replace(/\.33$/, "⅓")
      .replace(/\.66$/, "⅔")
      .replace(/\.25$/, "¼")
      .replace(/\.75$/, "¾");
  }

  if (qty === 0 || qty === "0") {
    return "";
  } else {
    // If the quanity begins with 0 and is not a decimal, remove the 0
    if (qty.toString().charAt(0) === "0" && qty.toString().charAt(1) !== ".") {
      qty = qty.toString().slice(1);
    }
    return {
      quantity: qty,
      quantityRounded: isNaN(qty) ? qty : Math.round(qty * 100) / 100,
    };
  }
};

export default tidyQuantityIntoFractions;
