import { CachedRecipeData, PlannerWithDaysAndRecipes } from "@/types/planner";

async function fetchAddRecipeToPlanner(
  recipeId: string | null,
  plannerUid: string,
  userId: string,
  options?: {
    cachedData?: CachedRecipeData | null;
    plannerDayUid?: string | null;
  }
): Promise<PlannerWithDaysAndRecipes> {
  try {
    // Convert userId to number
    const userIdNumber = parseInt(userId);
    if (isNaN(userIdNumber)) {
      throw new Error("Invalid User ID");
    }

    const response = await fetch("/api/planner/recipes/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipeId,
        plannerUid,
        userId: userIdNumber,
        ...options,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error adding recipe:", errorData.error);
      throw new Error(errorData.error);
    }

    const data = await response.json();

    console.log("Recipe added successfully");
    return data.planner;
  } catch (error) {
    console.error("Error adding recipe:", error.message);
    throw error;
  }
}

export default fetchAddRecipeToPlanner;
