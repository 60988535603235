import { GeoPreferenceContext } from "@/contexts/GeoPreferenceContext";
import Tippy from "@tippyjs/react";
import clsx from "clsx";
import { useContext, useState } from "react";

const MeasurementSelector = ({}) => {
  const { measurement, setMeasurement } = useContext(GeoPreferenceContext);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const inside = (
    <div className="flex gap-2 p-4 text-xs">
      <button
        className={clsx("button mx-auto", {
          "button-light": measurement === "metric",
          "button-dark": measurement !== "metric",
        })}
        onClick={(e) => {
          setIsOptionsOpen(false);
          e.preventDefault();
          setMeasurement("metric");
        }}
      >
        Metric
      </button>
      <button
        className={clsx("button mx-auto", {
          "button-light": measurement === "imperial",
          "button-dark": measurement !== "imperial",
        })}
        onClick={(e) => {
          setIsOptionsOpen(false);
          e.preventDefault();
          setMeasurement("imperial");
        }}
      >
        Imperial
      </button>
    </div>
  );

  return (
    <>
      <Tippy
        content={inside}
        className="ingredient-options"
        interactive
        placement="bottom"
        visible={isOptionsOpen}
        arrow
        onClickOutside={() => {
          setIsOptionsOpen(false);
        }}
        animation={false}
      >
        <button
          className={`flex h-8 w-8 shrink-0 items-center justify-center rounded-full border bg-zinc-100 text-zinc-800 opacity-100 transition duration-300 disabled:animate-none disabled:opacity-20 `}
          aria-label="Open ingredients settings"
          onClick={() => {
            setIsOptionsOpen(!isOptionsOpen);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="14"
            fill="none"
            viewBox="0 0 16 14"
          >
            <path
              fill="#000"
              fillRule="evenodd"
              d="M5.27 9.376A3.088 3.088 0 010 7.192c0-.295.24-.535.535-.535H5.64a.535.535 0 01.535.535c0 .82-.325 1.605-.904 2.184zm-2.182-.167a2.017 2.017 0 001.944-1.481H1.143a2.017 2.017 0 001.945 1.481zM3.485 2.02a1.09 1.09 0 01.373-.433c.176-.115.422-.19.782-.077l.013.004c1.027.324 2.235.705 3.27.854a.535.535 0 10.154-1.06C7.14 1.173 6.016.821 4.962.488 4.28.275 3.702.41 3.272.693a2.15 2.15 0 00-.786.941l-.011.027-1.168 3.99a.535.535 0 101.027.3l1.151-3.93z"
              clipRule="evenodd"
            ></path>
            <path
              fill="#000"
              fillRule="evenodd"
              d="M2.775 1.598a.535.535 0 01.677.338l1.232 3.676a.535.535 0 01-1.015.34L2.437 2.276a.535.535 0 01.338-.678zM10.728 9.405a3.079 3.079 0 004.369 0A3.115 3.115 0 0016 7.21a.535.535 0 00-.535-.535H10.36a.535.535 0 00-.535.535c0 .823.324 1.612.903 2.195zm2.184-.16a2.008 2.008 0 01-1.424-.595 2.04 2.04 0 01-.521-.904h3.891c-.092.34-.27.652-.52.904a2.008 2.008 0 01-1.426.595zM12.515 2.02a1.091 1.091 0 00-.373-.433c-.176-.115-.422-.19-.782-.077l-.013.004c-1.027.324-2.236.705-3.27.854a.535.535 0 11-.154-1.06c.936-.135 2.061-.487 3.115-.82.682-.214 1.26-.078 1.69.204.41.267.666.652.786.941l.011.027 1.168 3.99a.535.535 0 11-1.027.3l-1.151-3.93z"
              clipRule="evenodd"
            ></path>
            <path
              fill="#000"
              fillRule="evenodd"
              d="M13.225 1.598a.535.535 0 00-.677.338l-1.232 3.676a.535.535 0 001.015.34l1.232-3.676a.535.535 0 00-.338-.678zM8.018 0c.296 0 .535.24.535.535V12.8a.535.535 0 01-1.07 0V.535c0-.295.24-.535.535-.535z"
              clipRule="evenodd"
            ></path>
            <path
              fill="#000"
              fillRule="evenodd"
              d="M5.323 13.104c0-.295.24-.535.535-.535h4.248a.535.535 0 110 1.07H5.858a.535.535 0 01-.535-.535z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </Tippy>
    </>
  );
};

export default MeasurementSelector;
