import IngredientCheckButton from "@/components/IngredientCheckButton";
import PopoverArrow from "@/components/PopoverArrow";
import ScaledIngredientLabel from "@/components/ScaledIngredientLabel";
import ScaledIngredientQuantity from "@/components/ScaledIngredientQuantity";
import { GeoPreferenceContext } from "@/contexts/GeoPreferenceContext";
import { PlannerContext } from "@/contexts/PlannerContext";
import normalizeRecipeIngredients from "@/lib/ingredients/normalizeRecipeIngredients";
import pantryIngredients from "@/lib/ingredients/pantryIngredients";
import combineIngredients from "@/lib/planner/combineIngredients";
import { Dialog, Tab, Transition } from "@headlessui/react";
import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import Link from "next/link";
import { Fragment, useContext, useEffect, useState } from "react";
import { Info, X } from "react-feather";
import { useLocalStorage } from "react-use";
import MeasurementSelector from "../MeasurementSelector";
import Image from "next/image";
import { EllipsisHorizontalIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { debounce, set } from "lodash";
import { removeRecipeFromShoppingList } from "./ShoppingListController";
import Spinner from "@/components/Spinner";
import Counter from "@/components/Counter";
import Button from "@/components/Button";

const MashBowlIcon = ({ className }) => {
  return (
    <div data-svg-wrapper className={className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.6448 7.65716C11.1192 7.33275 11.896 6.99704 12.746 6.96728L14.3763 3.78039C14.6876 3.17086 15.3977 2.93336 15.9977 3.24051C16.587 3.5417 16.8299 4.28276 16.5281 4.87205C16.4876 4.95121 16.4352 5.028 16.3811 5.09407L14.4418 7.39406C14.7864 7.6012 15.1192 7.89049 15.4251 8.2893C15.6829 8.6262 15.6352 9.02799 15.2912 9.29048C15.162 9.38929 15.0096 9.45656 14.8579 9.45656C14.643 9.45656 14.4311 9.34108 14.2906 9.15715C14.1132 8.92561 13.9221 8.7637 13.7269 8.64584C12.6858 8.01549 11.5097 8.81549 11.4513 8.85537C11.1364 9.07084 10.7656 9.0012 10.5162 8.71906C10.4966 8.69644 10.4757 8.67561 10.4585 8.65001C10.2114 8.2887 10.2662 7.91549 10.6442 7.65775L10.6448 7.65716ZM17.0257 10.4089C16.9352 10.306 16.8049 10.2464 16.6674 10.2464H5.71205C5.72098 9.9262 5.79062 9.24822 6.19538 8.80834C6.48407 8.49406 6.9055 8.34168 7.48407 8.34168C7.87812 8.34168 8.19835 8.02144 8.19835 7.6274C8.19835 7.30299 8.3305 7.04347 8.60133 6.83216C9.2918 6.29466 10.1829 6.31252 10.8549 6.44407C11.243 6.51966 11.6168 6.32859 11.7138 5.94585C11.8102 5.56371 11.5876 5.13693 11.1972 5.078C10.3728 4.9524 8.73407 4.91907 7.72455 5.70419C7.28883 6.04347 6.99598 6.46907 6.85907 6.95835C6.16086 7.06549 5.58527 7.36132 5.1442 7.84109C4.40789 8.64108 4.29301 9.72739 4.28289 10.2464H3.33349C3.19659 10.2464 3.06563 10.306 2.97516 10.4089C2.88468 10.5119 2.84301 10.6488 2.86147 10.7857C3.35075 14.4542 4.81444 16.1982 4.87634 16.2702C4.94063 16.3458 5.02634 16.3988 5.12217 16.422C5.20194 16.4422 7.11741 16.894 9.99954 16.894C12.8817 16.894 14.7965 16.4422 14.8775 16.422C14.9733 16.3982 15.059 16.3452 15.1233 16.2702C15.1858 16.1982 16.6495 14.4542 17.1388 10.7857C17.1567 10.6488 17.1156 10.5125 17.0251 10.4089H17.0257Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

const ShoppingList = ({ shoppingList }) => {
  const recipes = shoppingList?.days?.[0]?.dayRecipes;
  const [combinedIngredients, setCombinedIngredients] = useState([]);
  const [ingredientsByRecipe, setIngredientsByRecipe] = useState([]);

  // const [completedRecipeCount, setCompletedRecipeCount] = useState(0);

  const [checkedIngredients, setCheckedIngredients] = useLocalStorage(
    `checkedIngredients-shopping-list`,
    []
  );

  const { measurement } = useContext(GeoPreferenceContext);

  const { isShoppingListOpen, setIsShoppingListOpen, updatePlanner } =
    useContext(PlannerContext);

  const handleClose = () => {
    setIsShoppingListOpen(false);
  };

  useEffect(() => {
    if (!recipes || !recipes.length) return;

    // Loop through each dayRecipe and get the ingredients for each recipe
    setCombinedIngredients(combineIngredients(recipes, measurement));

    // We also need a list of normalized ingredients, so we can show
    // a list of ingredients by recipe
    const normalizedDayRecipes = recipes.map((recipe) => {
      // Get the recipe ingredients
      const recipeIngredients = recipe.recipe?.cachedData?.recipeIngredients;

      // If there are no recipe ingredients for some reason, return
      if (!recipeIngredients || recipeIngredients === undefined) return;

      // Remove ingredients that are in the pantry, and ingredient headers
      const filteredRecipeIngredients = recipeIngredients.filter(
        (ingredient) => {
          // if the ingredient is a header, return false
          if (ingredient.typeHandle === "ingredientHeader") return false;

          // If the ingredient is in the pantry, return false
          if (
            pantryIngredients.includes(parseInt(ingredient.ingredient?.[0]?.id))
          )
            return false;

          return true;
        }
      );

      // Normalize the recipe ingredients
      const normalizedRecipeIngredients = normalizeRecipeIngredients(
        filteredRecipeIngredients,
        recipe.baseServingSize || 4,
        false,
        measurement
      );

      return {
        ...recipe,
        normalizedIngredients: normalizedRecipeIngredients,
      };
    });

    setIngredientsByRecipe(normalizedDayRecipes);
  }, [recipes, measurement]);

  const handleRemoveFromShoppingList = debounce(
    async ({ recipe }) => {
      try {
        const newPlanner = await removeRecipeFromShoppingList({
          recipe,
          shoppingList,
        });

        updatePlanner(shoppingList.uid, newPlanner, true);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    1000,
    { leading: true, trailing: false }
  );

  return (
    <Transition show={isShoppingListOpen} as={Fragment}>
      <Dialog
        as="div"
        className={clsx("PlannerShoppingList", "fixed inset-0 z-50")}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={clsx(
              "PlannerShoppingList__overlay",
              "fixed inset-0 bg-zinc-800 bg-opacity-25"
            )}
          />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom=" translate-x-full"
          enterTo=" translate-x-0"
          leave="ease-in duration-300"
          leaveFrom=" translate-x-0"
          leaveTo=" translate-x-full"
        >
          <Dialog.Panel
            className={clsx(
              "PlannerShoppingList__panel",
              "top-offset-header absolute right-0 flex h-screen w-full transform flex-col bg-white shadow-xl lg:w-1/2"
            )}
          >
            <div
              className={clsx(
                "overflow-y-scroll scrollbar scrollbar-track-transparent scrollbar-thumb-black scrollbar-w-1"
              )}
            >
              <Dialog.Title
                as="div"
                className={clsx(
                  "PlannerShoppingList__title",
                  "z-10 flex h-16 shrink-0 items-center border-b bg-white px-6"
                )}
              >
                <div className={clsx("text-base")}>Shopping List</div>
                <button
                  className={clsx(
                    "ml-auto flex h-7 w-7 items-center justify-center rounded-full transition-opacity hover:opacity-75"
                  )}
                  onClick={handleClose}
                >
                  <X size={18} strokeWidth={1.5} />
                </button>
              </Dialog.Title>

              <Tab.Group>
                <div
                  className={clsx(
                    "sticky top-0 z-20 flex flex-col justify-center border-b bg-white px-6 py-6"
                  )}
                >
                  <div className="flex items-center gap-x-2">
                    <Tab.List className={clsx("tab-list-sm mr-auto")}>
                      <Tab className={clsx("tab space-x-1.5")}>
                        <div>
                          <span className="inline-block">Recipes</span>
                        </div>
                      </Tab>
                      <Tab className={clsx("tab space-x-1.5")}>
                        <div>
                          <span className="inline-block">Ingredients</span>
                        </div>
                        <span
                          className={clsx(
                            "hidden pt-[2px] text-xs  text-zinc-500 sm:block"
                          )}
                        >
                          {checkedIngredients.length} /{" "}
                          {combinedIngredients.length}
                        </span>
                      </Tab>
                    </Tab.List>
                    <MeasurementSelector />
                  </div>
                </div>

                <Tab.Panels
                  className={clsx(
                    "PlannerShoppingList__ingredients",
                    "px-6 font-body text-base lg:pr-6"
                  )}
                >
                  <Tab.Panel>
                    {!recipes || !recipes.length ? (
                      <EmptyState />
                    ) : (
                      <_RecipesList
                        ingredientsByRecipe={ingredientsByRecipe}
                        plannerUid={shoppingList.uid}
                        handleRemoveFromShoppingList={
                          handleRemoveFromShoppingList
                        }
                      />
                    )}
                  </Tab.Panel>
                  <Tab.Panel>
                    {!recipes || !recipes.length ? (
                      <EmptyState />
                    ) : (
                      <_CombinedIngredients
                        combinedIngredients={combinedIngredients}
                        checkedIngredients={checkedIngredients}
                        setCheckedIngredients={setCheckedIngredients}
                      />
                    )}
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

const EmptyState = () => {
  return (
    <div className="mt-20 flex flex-col items-center justify-center text-center">
      <Image
        src="/images/empty-planner.png"
        width={300}
        height={200}
        alt="Empty state"
      />
      <div>
        <div className="mt-6 font-sans text-lg font-bold">
          Your list is empty
        </div>
        <div className="font-body text-sm font-medium text-zinc-500">
          Add recipes to your quick shopping list.
        </div>
      </div>
    </div>
  );
};

const _CombinedIngredients = ({
  combinedIngredients,
  checkedIngredients,
  setCheckedIngredients,
}) => {
  const recipesWithQuantity = combinedIngredients.filter((ingredient) => {
    return ingredient.quantity > 0;
  });

  const recipesWithoutQuantity = combinedIngredients.filter((ingredient) => {
    return ingredient.quantity === 0;
  });

  return (
    <>
      {recipesWithQuantity.map((ingredient) => {
        const isChecked = checkedIngredients.includes(ingredient.uniqueKey);

        return (
          <_Ingredient
            key={ingredient.uniqueKey}
            ingredient={ingredient}
            isChecked={isChecked}
            checkedIngredients={checkedIngredients}
            setCheckedIngredients={setCheckedIngredients}
          />
        );
      })}

      {recipesWithoutQuantity.length > 0 && (
        <>
          <h3 className="mt-12">Optional Ingredients</h3>

          {recipesWithoutQuantity.map((ingredient) => {
            const isChecked = checkedIngredients.includes(ingredient.uniqueKey);

            return (
              <_Ingredient
                key={ingredient.uniqueKey}
                ingredient={ingredient}
                isChecked={isChecked}
                checkedIngredients={checkedIngredients}
                setCheckedIngredients={setCheckedIngredients}
              />
            );
          })}
        </>
      )}
    </>
  );
};

const _RecipesList = ({
  ingredientsByRecipe,
  plannerUid,
  handleRemoveFromShoppingList,
}) => {
  const { findPlannerByUid, savePlanner } = useContext(PlannerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [openServingSizeModalId, setOpenServingSizeModalId] = useState(null);
  const [newServingSize, setNewServingSize] = useState(null);

  function closeModal() {
    if (isLoading) return;

    setOpenServingSizeModalId(null);
    setNewServingSize(null);
  }

  function openModal(recipe) {
    setOpenServingSizeModalId(recipe.id);
  }
  const handleRemoveClick = async (recipe) => {
    if (isLoading) return;

    setIsLoading(true);
    await handleRemoveFromShoppingList({ recipe });
    setIsLoading(false);
    setOpenPopoverId(null);
  };

  return (
    <div className={clsx("space-y-6")}>
      {ingredientsByRecipe.map((dayRecipe) => {
        const cachedRecipe = dayRecipe?.recipe?.cachedData;

        if (!cachedRecipe) return;

        const handleServingSizeChange = (newServingSize) => {
          setNewServingSize(newServingSize);
        };

        const handleSave = async () => {
          if (isLoading) return;

          try {
            setIsLoading(true);
            // Find the planner that contains the dayRecipe
            const plannerToUpdate = findPlannerByUid(plannerUid);

            // Create a new planner object with the updated serving size for the dayRecipe
            const updatedPlanner = {
              ...plannerToUpdate,
              days: plannerToUpdate.days.map((day) => ({
                ...day,
                dayRecipes: day.dayRecipes.map((dr) =>
                  dr.uid === dayRecipe.uid
                    ? { ...dr, servingSize: newServingSize }
                    : dr
                ),
              })),
            };

            await savePlanner(plannerUid, updatedPlanner);
            closeModal();
          } catch (error) {
            console.error("Error:", error);
          } finally {
            setIsLoading(false);
            setNewServingSize(null);
          }
        };

        return (
          <div key={dayRecipe.id} className={clsx("pt-4")}>
            <div className={clsx("flex items-center gap-4")}>
              <Image
                src={cachedRecipe.image[0].url}
                width={64}
                height={64}
                alt={cachedRecipe.image[0].title}
                className="rounded-lg"
              />
              <div>
                <Link
                  href={`/${cachedRecipe.uri}`}
                  className={clsx(
                    "font-body text-sm font-medium text-[#27262b]"
                  )}
                  target="_blank"
                >
                  {cachedRecipe.title}
                </Link>
                <div className="font-body text-[13px] font-medium text-zinc-500">
                  {dayRecipe.servingSize} serving
                  {dayRecipe.servingSize > 1 && "s"}
                </div>
              </div>
              <div className={clsx("ml-auto size-6 cursor-pointer")}>
                <Popover.Root
                  open={openPopoverId === cachedRecipe.id}
                  onOpenChange={(isOpen) =>
                    setOpenPopoverId(isOpen ? cachedRecipe.id : null)
                  }
                >
                  <Popover.Anchor asChild>
                    <div>
                      <Popover.Trigger
                        className={clsx("relative z-0 flex items-center")}
                      >
                        <EllipsisHorizontalIcon className="size-6" />
                      </Popover.Trigger>
                    </div>
                  </Popover.Anchor>
                  <Popover.Content
                    className={clsx(
                      "relative z-10 rounded-xl bg-zinc-900 text-white shadow"
                    )}
                    side="bottom"
                    collisionPadding={20}
                    hideWhenDetached
                  >
                    <div className="font-body text-sm font-medium">
                      <div
                        className="flex gap-2 rounded-t-xl px-4 py-3 hover:bg-zinc-700"
                        onClick={() => openModal(cachedRecipe)}
                      >
                        <MashBowlIcon className="size-5" />
                        <div>Adjust servings</div>
                      </div>
                      <div className="h-[1px] w-full bg-[#403F46]">&nbsp;</div>
                      <div
                        className="flex gap-2 rounded-b-xl px-4 py-3 hover:bg-zinc-700"
                        onClick={() => handleRemoveClick(cachedRecipe)}
                      >
                        {isLoading ? (
                          <Spinner />
                        ) : (
                          <XMarkIcon className="size-5" />
                        )}
                        <div>Remove</div>
                      </div>
                    </div>
                    <Popover.Arrow width={11} height={5} asChild>
                      <PopoverArrow />
                    </Popover.Arrow>
                  </Popover.Content>
                </Popover.Root>
              </div>
            </div>
            <Transition
              appear
              show={openServingSizeModalId === cachedRecipe.id}
              as={Fragment}
            >
              <Dialog as="div" className="relative z-60" onClose={closeModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-[20px] bg-white p-6 text-left align-middle shadow-xl transition-all">
                        <Dialog.Title
                          as="div"
                          className="font-sans text-lg font-bold text-gray-800"
                        >
                          Adjust servings
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="font-body text-base font-medium text-gray-800">
                            Enter the new serving amount for{" "}
                            {cachedRecipe.title}
                          </p>
                        </div>
                        <div className="mx-auto my-6 w-fit">
                          <Counter
                            startingNumber={dayRecipe.servingSize}
                            onChange={handleServingSizeChange}
                          />
                        </div>
                        <div className="flex justify-between gap-2">
                          <Button onClick={closeModal} withBorder isFull>
                            Cancel
                          </Button>
                          <Button
                            onClick={handleSave}
                            withBorder
                            theme="light"
                            isFull
                            isLoading={isLoading}
                          >
                            Save
                          </Button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          </div>
        );
      })}
    </div>
  );
};

const _Ingredient = ({
  ingredient,
  isChecked,
  checkedIngredients,
  setCheckedIngredients,
  servingSize = 1,
}) => {
  const toggleIngredientCheck = () => {
    if (checkedIngredients.includes(ingredient.uniqueKey)) {
      setCheckedIngredients(
        checkedIngredients.filter((key) => key !== ingredient.uniqueKey)
      );
    } else {
      setCheckedIngredients([...checkedIngredients, ingredient.uniqueKey]);
    }
  };

  const _ReferencedIngredients = () => {
    // We need to filter out any duplicates, based on recipe.id
    const uniqueRecipes = ingredient.recipes.reduce((acc, recipe) => {
      if (acc.find((r) => r.id === recipe.id)) {
        return acc;
      } else {
        return [...acc, recipe];
      }
    }, []);

    return (
      <div className={clsx("flex max-w-xs flex-col font-body text-sm")}>
        <div className={clsx("mb-1 text-zinc-500")}>Used in:</div>
        <div className={clsx("divide-y divide-white/20")}>
          {uniqueRecipes.map((recipe) => {
            return (
              <Link
                key={recipe.id}
                href={`/${recipe.uri}`}
                className={clsx("flex py-1")}
                target="_blank"
              >
                {recipe.title}
              </Link>
            );
          })}
        </div>
      </div>
    );
  };

  const showReferencedRecipes = ingredient.recipes?.length > 0 && !isChecked;

  return (
    <>
      <div
        className={clsx(
          "PlannerShoppingList__ingredient",
          "group flex w-full items-center border-b py-2 text-left focus:border-zinc-200 lg:py-3"
        )}
      >
        <div
          className={clsx("flex grow items-center", {
            "opacity-25": isChecked,
          })}
        >
          <div className={clsx("w-2/6 font-sans text-sm lg:text-base")}>
            <ScaledIngredientQuantity
              ingredient={ingredient}
              scale={servingSize}
            />
          </div>
          <div className={clsx("w-4/6 font-body text-base md:text-lg")}>
            <div className={clsx("pr-6")}>
              <div className={clsx("flex items-center space-x-2")}>
                <div className={clsx("shrink")}>
                  <ScaledIngredientLabel ingredient={ingredient} />
                </div>
                {showReferencedRecipes && (
                  <div>
                    <Popover.Root>
                      <Popover.Anchor asChild>
                        <div>
                          <Popover.Trigger
                            className={clsx("relative z-0 flex items-center")}
                          >
                            <div
                              className={clsx(
                                "text-zinc-500 hover:text-zinc-800"
                              )}
                            >
                              <Info size={18} strokeWidth={1.5} />
                            </div>
                          </Popover.Trigger>
                        </div>
                      </Popover.Anchor>
                      <Popover.Content
                        className={clsx("tooltip-content relative z-10")}
                        side="bottom"
                        collisionPadding={20}
                        hideWhenDetached
                      >
                        <_ReferencedIngredients />
                        <Popover.Arrow width={11} height={5} asChild>
                          <PopoverArrow />
                        </Popover.Arrow>
                      </Popover.Content>
                    </Popover.Root>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <IngredientCheckButton
          isChecked={isChecked}
          onClick={toggleIngredientCheck}
        />
      </div>
    </>
  );
};

export default ShoppingList;
